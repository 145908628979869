import Vue from 'vue'
import App from './App.vue'
import VueTouch from 'vue-touch'
import gsap from 'gsap'
import router from './router'
import i18n from './i18n'
import gtagPlugin from './plugins/gtagPlugin'

Vue.config.productionTip = false
Vue.use(VueTouch, {name: 'v-touch'})
Vue.use(gsap);
Vue.use(gtagPlugin);

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
