export default {
    install(Vue) {
        window.gtagDebug = false;

        let gtagHandler = function (type, eventType, payload) {
            if (window.gtagDebug)
                console.log('[EVENT TRIGGERED] gtag("event", "' + eventType + '", ' + JSON.stringify(payload) + ');');
            if (typeof gtag !== "undefined")
                // eslint-disable-next-line no-undef
                gtag(type, eventType, payload);
        }

        let payloadPreprocessor = function(payload) {
            let id = parseInt(payload.id, 10);

            if (payload.lang === "fr")
                payload.id = (id + 1).toString(10);
            else if (payload.lang === "it")
                payload.id = (id + 2).toString(10);

            return payload;
        }

        Vue.directive('gtag', {
            bind: function (el, binding) {
                el.addEventListener('click', function () {
                    gtagHandler('event', binding.value[0], payloadPreprocessor(binding.value[1]));
                });
            }
        })

        Vue.prototype.$gtag = function (eventType, payload) {
            gtagHandler('event', eventType, payloadPreprocessor(payload));
        }
    }
};