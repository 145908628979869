import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from "@/i18n";

function load(component) {
// '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const routes = [{
  path: '/:locale',
  component: {
    props: ['appData'],
    template: "<router-view :app-data='appData'></router-view>"
  },
  beforeEnter: (to, from, next) => {
    const locale = to.params.locale;
    const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
    if (!supported_locales.includes(locale)) return next('de');
    if (i18n.locale !== locale) {
      i18n.locale = locale;
    }
    return next()
  },
  children: [
    { name: 'home', path: '', component: load('SceneHome'), props: route => ({ position: route.query.p }) },
    { name: 'workshop', path: 'workshop', component: load('SceneWorkshop') },
    { name: 'accessories', path: 'accessories', component: load('SceneAccessories') },
    { name: 'tireHotel', path: 'tire-hotel', component: load('SceneTireHotel') },
    { name: 'batteryCheck', path: 'battery-check', component: load('SceneBatteryCheck') },
    { name: 'wheels', path: 'wheels', component: load('SceneWheels') },
    { name: 'quiz', path: 'quiz', component: load('SceneQuiz') },
    { name: 'competition', path: 'competition', component: load('SceneCompetition') },
  ]
},
{
  path: '*',
  redirect() {
    return process.env.VUE_APP_I18N_LOCALE;
  }
}];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (typeof to.params.locale !== "undefined")
    window.appLocale = to.params.locale;
  else
    window.appLocale = "de"
  next();
});

export default router
