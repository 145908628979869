<template>
  <div id="app">
      <router-view v-bind:app-data="appData"></router-view>
    <!--<div class="curtain"></div>-->
  </div>
</template>

<script>
//import gsap from "gsap";

import debounce from "lodash.debounce";

export default {
  name: 'App',
  components: {

  },
  data: function () {
    return {
      appData: {
        dimensions: {
          width: window.innerWidth,
          height: window.innerHeight
        }
      }
    }
  },
  methods: {
    // enter: function(el, done) {
    //   gsap.fromTo('.curtain', {autoAlpha: 1}, {duration: 5, autoAlpha: 0}).eventCallback('onComplete', done)
    // },
    // leave: function(el, done) {
    //   gsap.to(el, {})
    // }
    enter: function(el, done) {
      done();
    },
    leave: function(el, done) {
      done();
    },
    resizeHandler: function () {
      this.appData.dimensions = {
        width: window.innerWidth,
        height: window.innerHeight
      };
    }
  },
  mounted() {
    window.addEventListener('resize', debounce(this.resizeHandler, 500));
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'DS Beta Custom';
  src: url('assets/fonts/DSBeta-Bold.eot');
  src: url('assets/fonts/DSBeta-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/DSBeta-Bold.woff2') format('woff2'),
  url('assets/fonts/DSBeta-Bold.woff') format('woff'),
  url('assets/fonts/DSBeta-Bold.ttf') format('truetype'),
  url('assets/fonts/DSBeta-Bold.svg#DSBeta-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS Beta Custom';
  src: url('assets/fonts/DSBeta-Regular.eot');
  src: url('assets/fonts/DSBeta-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/DSBeta-Regular.woff2') format('woff2'),
  url('assets/fonts/DSBeta-Regular.woff') format('woff'),
  url('assets/fonts/DSBeta-Regular.ttf') format('truetype'),
  url('assets/fonts/DSBeta-Regular.svg#DSBeta-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DS Title Medium';
  src: url('assets/fonts/DSTitleMedium-Regular.eot');
  src: url('assets/fonts/DSTitleMedium-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/DSTitleMedium-Regular.woff2') format('woff2'),
  url('assets/fonts/DSTitleMedium-Regular.woff') format('woff'),
  url('assets/fonts/DSTitleMedium-Regular.ttf') format('truetype'),
  url('assets/fonts/DSTitleMedium-Regular.svg#DSTitleMedium-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
  outline: none !important;
}

body, html {
  padding: 0;
  margin: 0;
}

#app {
  font-family: 'DS Beta Custom', Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #333333;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
strong {
  font-family: 'DS Beta Custom', Avenir, Helvetica, Arial, sans-serif;
font-weight: bold;
}
.scene {
  position: absolute;
  width: var(--scene-width);
  height: var(--scene-height);
  left: 0;
  top: 0;
  transform-origin: left top;
  transform: scale(var(--scene-scale));
}
.curtain {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: black;
  pointer-events: none;
}
.btn-grp-vertical {
  text-align: left;
}
</style>
